import './_external-url-block.scss'

const externalUrlBlock = function() {

};

document.addEventListener('DOMContentLoaded', function(){
    externalUrlBlock()
}, false);

window.addEventListener('acf/loaded/external-url-block', function (e) {
    externalUrlBlock()
});